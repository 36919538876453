import React, { useContext } from "react";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import PropTypes from "prop-types";
import SVG_creating_report from "../../../assets/images/vector/pages/report-deals/SampleReport/creating_report.svg";
import { PAGES } from "../../../helpers/constants";
import { getTrueClasses } from "../../../helpers";
import StandardFormBlockContainer from "../../../containers/common/forms/StandardFormBlockContainer";
import "./index.scss";

const SampleReport = ({ openDealsModal, sampleReportRef }) => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const redirectUrlSecondStateButton =
    "/goto/?url=https://downloads.ctfassets.net/0yu6ph4occja/5E1b8Z9QxCkiF3mIItbVaL/136c2505e59658cf5f009f4c49b51045/SAMPLE_Report.pdf";
  return (
    <section className="PgRD-SR">
      <div className="PgRD-SR__ref" ref={sampleReportRef} />
      <div className="container">
        <div className="PgRD-SR__wrap">
          <h2
            className={getTrueClasses(
              "PgRD-SR__title",
              isAuth && "PgRD-SR__title_secondState"
            )}
          >
            Get the sample product research report in your inbox with detailed
            Amazon product analytics
          </h2>
          <div className="PgRD-SR-form" />
          <StandardFormBlockContainer
            buttonTextFirstState="GET NOW"
            buttonTextSecondState="GET NOW"
            page={PAGES.REPORT_DEALS}
            isIntro
            redirect={false}
            redirectUrlSecondStateButton={redirectUrlSecondStateButton}
            onSuccess={openDealsModal}
            placeholder="Enter your email here"
            customClass="PgRD-SR-form"
          />
          <img className="PgRD-SR__svg" src={SVG_creating_report} alt="" />
        </div>
      </div>
    </section>
  );
};
SampleReport.propTypes = {
  openDealsModal: PropTypes.func,
};
export default SampleReport;
