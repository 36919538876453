import React from "react";
import PropTypes from "prop-types";

const FPLocationDescription = ({ location, money }) => {
  if (!location) {
    return null;
  }
  const description = `Great! Each product idea you receive will have monthly revenue of ${money[location]}+!`;

  return <p className="PgRD-FPLocation__Description">{description}</p>;
};

FPLocationDescription.propTypes = {
  location: PropTypes.string,
  money: PropTypes.object.isRequired,
};

export default FPLocationDescription;
