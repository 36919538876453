import React from "react";
import PropTypes from "prop-types";
import LinkSampleReport from "../../../common/LinkSampleReport";
import "./index.scss";

const SpecialOffer = ({ svg, setShowInfo, sampleReportRef }) => (
  <div className="PgRD-Pricing-SpecialOffer">
    <img src={svg} alt="10+" />
    <p className="PgRD-Pricing-SpecialOffer__text">Need More Product Ideas?</p>
    <p className="PgRD-Pricing-SpecialOffer__offer">
      Check out this Special Offer
    </p>
    <div
      className="PgRD-Pricing-SpecialOffer__button"
      onClick={() => setShowInfo(true)}
    >
      CONTACT CUSTOMER CARE
    </div>
    <LinkSampleReport
      isReportDealsPage
      isScrollLink
      refParameter={sampleReportRef}
    />
  </div>
);
SpecialOffer.propTypes = {
  svg: PropTypes.string,
  setShowInfo: PropTypes.func,
};

export default SpecialOffer;
