import React from "react";
import PNG from "../../../../assets/images/raster/pages/report-deals/Work/info.png";
import PNG_2x from "../../../../assets/images/raster/pages/report-deals/Work/info@2x.png";
import PNG_Tab from "../../../../assets/images/raster/pages/report-deals/Work/info@tab.png";
import PNG_Tab_2x from "../../../../assets/images/raster/pages/report-deals/Work/info@tab2x.png";
import PNG_Mob from "../../../../assets/images/raster/pages/report-deals/Work/info@mob.png";
import PNG_Mob_2x from "../../../../assets/images/raster/pages/report-deals/Work/info@mob2x.png";
import WEBP from "../../../../assets/images/raster/pages/report-deals/Work/info.png";
import WEBP_2x from "../../../../assets/images/raster/pages/report-deals/Work/info@2x.png";
import WEBP_Tab from "../../../../assets/images/raster/pages/report-deals/Work/info@tab.png";
import WEBP_Tab_2x from "../../../../assets/images/raster/pages/report-deals/Work/info@tab2x.png";
import WEBP_Mob from "../../../../assets/images/raster/pages/report-deals/Work/info@mob.png";
import WEBP_Mob_2x from "../../../../assets/images/raster/pages/report-deals/Work/info@mob2x.png";
import "./index.scss";

const ThirdScreen = () => (
  <picture>
    <source
      type="image/webp"
      media="(min-width: 992px)"
      srcSet={`${WEBP} 1x, ${WEBP_2x} 2x`}
    />
    <source media="(min-width: 992px)" srcSet={`${PNG} 1x, ${PNG_2x} 2x`} />
    <source
      type="image/webp"
      media="(min-width: 768px) and (max-width: 991px)"
      srcSet={`${WEBP_Tab} 1x, ${WEBP_Tab_2x} 2x`}
    />
    <source
      media="(min-width: 768px) and (max-width: 991px)"
      srcSet={`${PNG_Tab} 1x, ${PNG_Tab_2x} 2x`}
    />
    <source
      type="image/webp"
      media="(max-width: 991px)"
      srcSet={`${WEBP_Mob} 1x, ${WEBP_Mob_2x} 2x`}
    />
    <source
      media="(max-width: 991px)"
      srcSet={`${PNG_Mob} 1x, ${PNG_Mob_2x} 2x`}
    />
    <img
      src={PNG}
      className="PgRD-Work__ThirdScreen"
      alt="img"
      loading="lazy"
    />
  </picture>
);

export default ThirdScreen;
