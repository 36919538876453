import React from "react";
import RoadMap from "./RoadMap";
import Points from "./Points";
import "./index.scss";

const HowWeDoIt = () => {
  return (
    <section className="PgRD-HowWeDoIt">
      <div className="container">
        <h2 className="PgRD-HowWeDoIt__title">HOW we do it</h2>
        <RoadMap />
        <div className="PgRD-HowWeDoIt__hr" />
        <div className="PgRD-HowWeDoIt-content">
          <div className="PgRD-HowWeDoIt-content__title">What you will get</div>
          <div className="PgRD-HowWeDoIt-info">
            <Points />
          </div>
        </div>
        <div className="PgRD-HowWeDoIt__text">
          The list is unique to your needs, meaning that we won’t share it with
          any other seller. You will have more time and higher chances of
          launching a product that will sell well.
        </div>
      </div>
    </section>
  );
};

export default HowWeDoIt;
