import React from "react";
import WORK_CONTENT from "./index.content";
import Item from "./Item";
import "./index.scss";

const Work = () => {
  const { listItem } = WORK_CONTENT;

  return (
    <section className="PgRD-Work">
      <div className="container">
        <h2 className="PgRD-Work__title">
          How does it work in the real world?
        </h2>
        <div className="PgRD-Work-content">
          {listItem.map((data, i) => (
            <Item key={i} data={data} i={i} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Work;
