import React from "react";
import AUTHOR_CONTENT from "./index.content";
import "./index.scss";

const Author = () => {
  const { PNG, WEBP, name, description } = AUTHOR_CONTENT;
  return (
    <div className="PgRD-CS-Author">
      <div className="PgRD-CS-Author__img">
        <picture>
          <source type="image/webp" srcSet={WEBP} />
          <img src={PNG} alt="" loading="lazy" />
        </picture>
      </div>
      <div className="PgRD-CS-Author__name">
        <div>{name}</div>
        <div>{description}</div>
      </div>
    </div>
  );
};

export default Author;
