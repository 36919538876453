import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";

const FPCategoryItems = ({
  categoryList,
  value,
  onClickCategory,
  customClass,
}) => (
  <ul className={`PgRD-FPCategory-${customClass}`}>
    {categoryList.map((category) => (
      <li
        key={category}
        className={getTrueClasses(
          "PgRD-FPCategory-item",
          value.length !== 0 &&
            value.includes(category) &&
            "PgRD-FPCategory-item_active"
        )}
        onClick={() => onClickCategory(category)}
      >
        <div className="PgRD-FPCategory-item__checkbox">
          <div className="PgRD-FPCategory-item__checkbox__inner" />
        </div>
        <div className="PgRD-FPCategory-item__name">{category}</div>
      </li>
    ))}
  </ul>
);

FPCategoryItems.propTypes = {
  categoryList: PropTypes.array.isRequired,
  value: PropTypes.array,
  onClickCategory: PropTypes.func.isRequired,
  customClass: PropTypes.string.isRequired,
};

export default FPCategoryItems;
