import React from "react";
import PropTypes from "prop-types";
import GIFLoader from "../../../assets/images/gif/loader.gif";
import PG_RD_FP_CONTENT from "./index.content";
import FPLocation from "./FPLocation";
import FPSelectionSection from "./FPSelectionSection";
import FPEmail from "./FPEmail";
import FPCriteriaSort from "./FPCriteriaSort";
import Message from "./Message";
import FPSubmitContainer from "../../../containers/report-deals/FPSubmitContainer";
import FPCategoryContainer from "../../../containers/report-deals/FPCategoryContainer";
import "./index.scss";

const FP = (props) => {
  const {
    isFinalPopupState,
    isLoading,
    stateUpdateHandler,
    listRef,
    closeMessage,
    setIsFinalPopupState,
    setIsLoading,
    checkIndexes,
    checkErrorIndexes,
    updateState,
  } = props;
  const {
    locationOfInterest,
    categoriesOfInterest,
    preferredPrices,
    differentProductSizes,
    productSize,
    criteriaSort,
  } = PG_RD_FP_CONTENT;
  return (
    <div className="PgRD-FP">
      <div className="PgRD-FP-wrapper" />
      {isFinalPopupState ? (
        <Message closeMessage={closeMessage} />
      ) : (
        <div className="PgRD-FP-content">
          <div className="PgRD-FP__row">
            <FPLocation
              locationOfInterest={locationOfInterest}
              stateUpdateHandler={stateUpdateHandler}
              componentRef={listRef[0]}
            />
          </div>
          <div className="PgRD-FP__row">
            <FPCategoryContainer
              categoryLists={categoriesOfInterest}
              stateUpdateHandler={stateUpdateHandler}
              componentRef={listRef[1]}
            />
          </div>
          <div className="PgRD-FP__row">
            <FPSelectionSection
              listOfAnswers={preferredPrices.priceList}
              title="3. What are your preferred prices? (U.S. dollar equivalent)"
              surveyFieldName="preferredPrices"
              stateUpdateHandler={stateUpdateHandler}
              type="IS_VALUE"
              componentRef={listRef[2]}
            />
          </div>
          <div className="PgRD-FP__row">
            <FPSelectionSection
              listOfAnswers={differentProductSizes.listOfAnswers}
              title="4. Would you consider selling things that come in different sizes, such as clothes (for example XL, L, M)?"
              surveyFieldName="differentProductSizes"
              stateUpdateHandler={stateUpdateHandler}
              type="IS_VALUE"
              componentRef={listRef[3]}
            />
          </div>
          <div className="PgRD-FP__row">
            <FPSelectionSection
              listOfAnswers={productSize.sizeList}
              title="5. What size of the product are you willing to sell?"
              surveyFieldName="productSize"
              stateUpdateHandler={stateUpdateHandler}
              type="IS_VALUE_LIST"
              componentRef={listRef[4]}
            />
          </div>
          <div className="PgRD-FP__row">
            <FPCriteriaSort
              title="6. Sort your criteria by importance from 1 to 5 (1 is the most important, 5 is the least important)"
              stateUpdateHandler={stateUpdateHandler}
              checkErrorIndexes={checkErrorIndexes}
              listTitles={criteriaSort.listTitles}
              componentRef={listRef[5]}
            />
          </div>
          <div className="PgRD-FP__row">
            <FPEmail
              title="7. Leave your email, so we can contact you "
              stateUpdateHandler={stateUpdateHandler}
              componentRef={listRef[6]}
            />
          </div>
          <FPSubmitContainer
            updateState={updateState}
            setIsLoading={setIsLoading}
            setIsFinalPopupState={setIsFinalPopupState}
            checkIndexes={checkIndexes}
          />
          {isLoading ? (
            <div className="PgRD-FP-loader">
              <img src={GIFLoader} alt="loader" />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

FP.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isFinalPopupState: PropTypes.bool.isRequired,
  closeMessage: PropTypes.func.isRequired,
  stateUpdateHandler: PropTypes.func.isRequired,
  listRef: PropTypes.array.isRequired,
  setIsFinalPopupState: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  checkIndexes: PropTypes.func.isRequired,
  checkErrorIndexes: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default FP;
