import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import FPTitle from "../FPTitle";
import "./index.scss";
import { useSelector } from "react-redux";

const FPEmail = ({ stateUpdateHandler, title, componentRef }) => {
  const {
    email: { error, value },
  } = useSelector((state) => state.fp);
  return (
    <div className="PgRD-FPEmail" ref={componentRef}>
      <FPTitle error={error} title={title} />
      <input
        placeholder="Enter your email here"
        className={getTrueClasses(
          "PgRD-FPEmail__input",
          !!value && "PgRD-FPEmail__input_active",
          error && "PgRD-FPEmail__input_error"
        )}
        value={value}
        name="email"
        onChange={(value) =>
          stateUpdateHandler("IS_INPUT", "email", "value", value)
        }
        type="email"
        maxLength={100}
      />
    </div>
  );
};

FPEmail.propTypes = {
  title: PropTypes.string.isRequired,
  stateUpdateHandler: PropTypes.func.isRequired,
};

export default FPEmail;
