import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SubmitPopupController from "../../controllers/common/SubmitPopupController";
import { checkValue } from "../../helpers";
import FPSubmit from "../../components/report-deals/FP/FPSubmit";
import { useSelector } from "react-redux";

const FPSubmitContainer = (props) => {
  const { updateState, setIsLoading, setIsFinalPopupState, checkIndexes } =
    props;

  const state = useSelector((state) => state.fp);

  const checkErrorsResponseIndexes = (key, indexes) => {
    const result = [];
    indexes.forEach((value) => {
      result.push(value === -2 ? -1 : value);
    });
    updateState(key, "responseIndexes", result);
    updateState(key, "error", checkIndexes(result));
  };
  const handlerValue = (value) => {
    return Array.isArray(value) ? value.join(", ") : value.trim();
  };

  const checkErrors = (state) => {
    const errorList = [];
    const result = [];
    // eslint-disable-next-line guard-for-in
    for (const key in state) {
      key !== "submit" && errorList.push(state[key].error);
      key !== "submit" && result.push(handlerValue(state[key].value));
    }
    const firstErrorIndex = errorList.findIndex((error) => error === true);
    const errors = errorList.filter(Boolean).length !== 0;

    updateState("submit", "result", result);
    updateState("submit", "firstErrorIndex", firstErrorIndex);
    updateState("submit", "isScrolled", true);
    updateState("submit", "errors", errors);
    updateState("submit", "errorsChecking", false);
    updateState("submit", "submitForm", true);
  };
  const checkValueErrors = (value) => {
    return Array.isArray(value) ? value.length === 0 : !value;
  };
  const handlerCriteriaSortValue = (responseIndexes, inputValue) => {
    const firstSubstring = responseIndexes.map((e) => e + 1).join(", ");
    const secondSubstring = inputValue.trim()
      ? `, ${inputValue.trim()}`
      : inputValue.trim();
    return firstSubstring + secondSubstring;
  };

  const handlerSubmit = () => {
    const listStateKeys = [];
    // eslint-disable-next-line guard-for-in
    for (const key in state) {
      key !== "submit" && listStateKeys.push(key);
    }
    listStateKeys.forEach((key) => {
      if (key === "criteriaSort") {
        checkErrorsResponseIndexes(key, state[key].responseIndexes);
        updateState(
          key,
          "value",
          handlerCriteriaSortValue(
            state[key].responseIndexes,
            state[key].inputValue
          )
        );
      } else if (key === "email") {
        updateState(key, "error", !!checkValue("email", state[key].value));
      } else {
        updateState(key, "error", checkValueErrors(state[key].value));
      }
    });
    updateState("submit", "errorsChecking", true);
  };
  useEffect(() => {
    state.submit.errorsChecking && checkErrors(state);
  }, [state.submit.errorsChecking]);

  useEffect(() => {
    if (state.submit.submitForm && !state.submit.errors) {
      new SubmitPopupController(
        state.submit.result,
        setIsLoading,
        setIsFinalPopupState
      );
    } else {
      updateState("submit", "isScrolled", false);
      updateState("submit", "submitForm", false);
    }
  }, [state.submit.submitForm]);

  return <FPSubmit handlerSubmit={handlerSubmit} />;
};
FPSubmitContainer.propTypes = {
  updateState: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setIsFinalPopupState: PropTypes.func.isRequired,
  checkIndexes: PropTypes.func.isRequired,
};

export default FPSubmitContainer;
