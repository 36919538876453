import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const FPSubmit = ({ handlerSubmit }) => (
  <div className="PgRD-FP__Submit" onClick={handlerSubmit}>
    submit
  </div>
);

FPSubmit.propTypes = {
  handlerSubmit: PropTypes.func.isRequired,
};

export default FPSubmit;
