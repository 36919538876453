import React from "react";
import PointIcon from "../../../../assets/images/vector/components/common/group-copy.svg";
import POINTS_CONTENT from "./index.content";
import "./index.scss";

const Points = () => {
  return (
    <div className="PgRD-HomeIntro-Points">
      {POINTS_CONTENT.map((text, i) => (
        <div key={i} className="PgRD-HomeIntro-Points__item">
          <img src={PointIcon} alt="" />
          <div>{text}</div>
        </div>
      ))}
    </div>
  );
};

export default Points;
