import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../../helpers";
import "./index.scss";

const FPEvaluationFields = (props) => {
  const { index, responseIndexes, onClickItem, checkErrorIndexes } = props;
  const activeIndex = responseIndexes[index];

  const result = [];
  for (let i = 0; i < 5; i++) {
    result.push(
      <li
        key={i}
        className={getTrueClasses(
          "PgRD-FPEvaluationFields__item",
          activeIndex !== -1 &&
            activeIndex === i &&
            "PgRD-FPEvaluationFields__item_active",
          checkErrorIndexes(responseIndexes, index) &&
            "PgRD-FPEvaluationFields__item_error",
          activeIndex !== -1 &&
            activeIndex !== i &&
            responseIndexes.includes(i) &&
            "PgRD-FPEvaluationFields__item_inactive"
        )}
        onClick={() => onClickItem(index, i)}
      >
        {i + 1}
      </li>
    );
  }

  return (
    <ul key={index} className="PgRD-FPEvaluationFields">
      {result}
    </ul>
  );
};

FPEvaluationFields.propTypes = {
  index: PropTypes.number.isRequired,
  onClickItem: PropTypes.func.isRequired,
  checkErrorIndexes: PropTypes.func.isRequired,
};

export default FPEvaluationFields;
