import React from "react";
import QuotesSVG from "../../../../assets/images/vector/pages/report-deals/CustomersSay/quotes.svg";
import TEXT_CONTENT from "./index.content";
import "./index.scss";

const Text = () => (
  <div className="PgRD-CS__Text">
    <img src={QuotesSVG} alt="" loading="lazy" />
    {TEXT_CONTENT.map((item, i) => (
      <p key={i}>{item}</p>
    ))}
  </div>
);

export default Text;
