import React from "react";
import Svg_select from "../../../../assets/images/vector/pages/report-deals/Work/select.svg";
import Svg_select_v2 from "../../../../assets/images/vector/pages/report-deals/Work/select-v2.svg";
import "./index.scss";

const SecondScreen = () => (
  <picture>
    <source media="(min-width: 1200px)" srcSet={Svg_select} />
    <source media="(max-width: 1199px)" srcSet={Svg_select_v2} />
    <img
      src={Svg_select}
      className="PgRD-Work__SecondScreen"
      alt="img"
      loading="lazy"
    />
  </picture>
);

export default SecondScreen;
