import axios from "axios";

const submitSheetModel = (
  result,
  sheetId = "1AEuJpvaXQbBVC0ANZ_HB0DUmKYH8PBL5BNIAAa-JbTo"
) => {
  return axios({
    method: "post",
    url:
      process.env.API_HOST + `/v1/gsheets/reports/contact?sheetId=${sheetId}`,
    data: result,
  });
};

export default submitSheetModel;
