import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import FPCategory from "../../components/report-deals/FP/FPCategory";

const FPCategoryContainer = (props) => {
  const { categoryLists, componentRef, stateUpdateHandler } = props;
  const { categories } = useSelector((state) => state.fp);
  const [isShowCategorySelectionPanel, setIsShowCategorySelectionPanel] =
    useState(false);

  const onClickCategory = (category) =>
    stateUpdateHandler("IS_VALUE_LIST", "categories", "value", category);
  useEffect(() => {
    const handleClick = (e) => {
      if (
        isShowCategorySelectionPanel &&
        componentRef.current &&
        !componentRef.current.contains(e.target)
      ) {
        setIsShowCategorySelectionPanel(false);
      }
    };
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [isShowCategorySelectionPanel]);

  return (
    <FPCategory
      categories={categories}
      onClickCategory={onClickCategory}
      categoryLists={categoryLists}
      stateUpdateHandler={stateUpdateHandler}
      componentRef={componentRef}
      setIsShowCategorySelectionPanel={setIsShowCategorySelectionPanel}
      isShowCategorySelectionPanel={isShowCategorySelectionPanel}
    />
  );
};
FPCategoryContainer.propTypes = {
  categoryLists: PropTypes.object.isRequired,
  stateUpdateHandler: PropTypes.func.isRequired,
};

export default FPCategoryContainer;
