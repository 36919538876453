import React from "react";
import SvgCloseIcon from "../../../../assets/images/vector/components/common/cn-modalClose.svg";
import "./index.scss";

const Message = ({ closeMessage }) => {
  return (
    <div className="PgRD-FP-Message">
      <div className="PgRD-FP-Message__content">
        <div className="PgRD-FP-Message__title">Thank you!</div>
        <div className="PgRD-FP-Message-text">
          It will take 8-10 working days to prepare the ordered report. If we
          have any questions, we will contact you via the email you left.
        </div>
        <div className="PgRD-FP-Message__close" onClick={closeMessage}>
          <img src={SvgCloseIcon} alt="x" />
        </div>
      </div>
    </div>
  );
};

export default Message;
