import submitSheetModel from "../../models/common/submitSheetModel";

class SubmitSheetController {
  constructor(result, finalFunction, sheetId) {
    this.submitPopup(result, finalFunction, sheetId);
  }

  submitPopup(result, finalFunction, sheetId) {
    submitSheetModel(result, sheetId)
      .then(() => {
        finalFunction();
      })
      .catch(() => alert("Please try again later"));
  }
}

export default SubmitSheetController;
