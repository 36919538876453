import React from "react"
import FirstScreen from "./FirstScreen";
import SecondScreen from "./SecondScreen";
import ThirdScreen from "./ThirdScreen";

const WORK_CONTENT = {
  listItem: [
    {
      mod: "first",
      screen: <FirstScreen />,
      text:<><b>Veronica</b> occupies a highly competitive niche - Kitchen Supplies. She <b>would like to earn more money,</b> but she doesn't have the resources to find new products to sell, so for now she just sells what she already has.</>,
    },
    {
      mod: "second",
      screen: <SecondScreen />,
      text:<><b>She ordered an AMZScout Individual Report</b> and selected “Home & Kitchen” from the drop-down menu.</>,
    },
    {
      mod: "third",
      screen: <ThirdScreen />,
      text: <>After just one week, she receives a hot product idea report. The report gave her a link with information about where she could buy the product, so she ordered a batch and started selling it on Amazon. <b>Her profits have increased by $10,000 a month.</b></>,
    },
  ],
}
export default WORK_CONTENT
