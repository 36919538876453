import React from "react";
import PropTypes from "prop-types";
import SvgCloseIcon from "../../../../assets/images/vector/components/common/cn-modalClose.svg";
import "./index.scss";

const InfoMessage = ({ closeInfo, show }) => {
  if (!show) return null;
  return (
    <div className="PgRD-Info-Message">
      <img
        className="PgRD-Info-Message__close"
        src={SvgCloseIcon}
        alt="x"
        onClick={closeInfo}
      />
      <p className="PgRD-Info-Message__title">
        Thank you for your information!
      </p>
      <p className="PgRD-Info-Message__text">
        You'll hear from us within 1 day.
      </p>
    </div>
  );
};
InfoMessage.propTypes = {
  show: PropTypes.bool,
  closeInfo: PropTypes.func,
};

export default InfoMessage;
