import React from "react";
import PointIcon from "../../../../assets/images/vector/components/common/group-copy.svg";
import HOW_WE_DO_IT_POINTS from "./index.content";
import Screenshot from "./Screenshot";
import "./index.scss";

const Points = () => {
  return (
    <div className="PgRD-HowWeDoIt-Points">
      {HOW_WE_DO_IT_POINTS.map((text, i) => (
        <div key={i} className="PgRD-HowWeDoIt-Points__item">
          <img src={PointIcon} alt="" loading="lazy" />
          {text}
        </div>
      ))}
      <Screenshot />
    </div>
  );
};

export default Points;
