import React from "react";
import "./index.scss";

const Guarantee = () => (
  <section className="PgRD-Guarantee">
    <div className="container">
      <p>48-hour money-back guarantee.</p>
      <p>
        Once your payment is submitted, you will be redirected to fill in a
        brief form with your criteria. You will receive a completed report
        within 8-10 working days.
      </p>
    </div>
  </section>
);

export default Guarantee;
