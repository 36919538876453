import PNG from "../../../../assets/images/raster/pages/report-deals/CustomersSay/RD-author.png";
import WEBP from "../../../../assets/images/raster/pages/report-deals/CustomersSay/RD-author.webp";

const AUTHOR_CONTENT = {
  name: "Susan",
  description: "eCom business owner",
  PNG,
  WEBP
};
export default AUTHOR_CONTENT;
