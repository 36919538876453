import React from "react";
import Svg_Idea from "../../../../assets/images/vector/pages/report-deals/Work/idea.svg";
import Svg_Idea_v2 from "../../../../assets/images/vector/pages/report-deals/Work/idea-v2.svg";
import "./index.scss";

const FirstScreen = () => (
  <picture>
    <source media="(min-width: 1366px)" srcSet={Svg_Idea} />
    <source media="(max-width: 1365px)" srcSet={Svg_Idea_v2} />
    <img
      src={Svg_Idea}
      className="PgRD-Work__FirstScreen"
      alt="img"
      loading="lazy"
    />
  </picture>
);

export default FirstScreen;
