import React from "react";
import "./index.scss";

const Possibility = () => {
  return (
    <section className="PgRD-Possibility">
      <div className="container">
        <div className="PgRD-Possibility-content">
          <h2 className="PgRD-Possibility__title">
            Right now, you have the chance to order a list of emerging product
            ideas, manually selected just for you according to your personal
            criteria, suitable for both private labels and brands.
          </h2>
          <p className="PgRD-Possibility__text">
            If you miss this opportunity, your competitors will take up 90% of
            sales in new niches - make the opposite happen.
          </p>
        </div>
      </div>
    </section>
  );
};
export default Possibility;
