import React from "react";
import PNGXxl from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@xxl.png";
import PNGXxl2x from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@xxl2x.png";
import PNGLg from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@lg.png";
import PNGLg2x from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@lg2x.png";
import PNGMd from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@md.png";
import PNGMd2x from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@md2x.png";
import PNGMob from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@mob.png";
import PNGMob2x from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@mob2x.png";
import WEBPXxl from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@xxl.webp";
import WEBPXxl2x from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@xxl2x.webp";
import WEBPLg from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@lg.webp";
import WEBPLg2x from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@lg2x.webp";
import WEBPMd from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@md.webp";
import WEBPMd2x from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@md2x.webp";
import WEBPMob from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@mob.webp";
import WEBPMob2x from "../../../../../assets/images/raster/pages/report-deals/HowWeDoIt/reportDeals-section2@mob2x.webp";

const HOW_WE_DO_IT_SCREENSHOT = {
  PNGXxl,
  PNGXxl2x,
  PNGLg,
  PNGLg2x,
  PNGMd,
  PNGMd2x,
  PNGMob,
  PNGMob2x,
  WEBPXxl,
  WEBPXxl2x,
  WEBPLg,
  WEBPLg2x,
  WEBPMd,
  WEBPMd2x,
  WEBPMob,
  WEBPMob2x
};
export default HOW_WE_DO_IT_SCREENSHOT;

