import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import "./index.scss";

const FPTitle = ({ error, title, customClass }) => (
  <p
    className={getTrueClasses(
      "PgRD-FPTitle",
      customClass,
      !!error && "PgRD-FPTitle_error"
    )}
  >
    {title} <span>required</span>
  </p>
);

FPTitle.propTypes = {
  error: PropTypes.bool,
  title: PropTypes.string,
  customClass: PropTypes.string,
};

export default FPTitle;
