import React from "react";
import PropTypes from "prop-types";
import InfoForm from "./InfoForm";
import InfoMessage from "./InfoMessage";
import "./index.scss";

const Info = (props) => {
  const { closeInfo, getInfoState, handlerSubmit, handlerInputValue } = props;
  return (
    <div className="PgRD-Info">
      <div className="PgRD-Info__wrapper">
        <InfoForm
          closeInfo={closeInfo}
          getInfoState={getInfoState}
          handlerSubmit={handlerSubmit}
          handlerInputValue={handlerInputValue}
        />
        <InfoMessage
          show={!getInfoState.isShowInfoForm}
          closeInfo={closeInfo}
        />
      </div>
    </div>
  );
};
Info.propTypes = {
  getInfoState: PropTypes.object,
  closeInfo: PropTypes.func,
  handlerSubmit: PropTypes.func,
  handlerInputValue: PropTypes.func,
};

export default Info;
