import React from "react";
import PropTypes from "prop-types";
import PG_RD_PRICING_CONTENT from "./index.content";
import SpecialOffer from "./SpecialOffer";
import Offer from "./Offer";
import "./index.scss";

const Pricing = ({ pricingRef, setShowInfo, sampleReportRef }) => {
  const { listOffer } = PG_RD_PRICING_CONTENT;
  return (
    <section className="PgRD-Pricing">
      <div ref={pricingRef} className="PgRD-Pricing__ref" />
      <div className="container">
        <h2 className="PgRD-Pricing__title">
          Order your Individual Report now and get ready to launch the next
          bestseller in a week!
        </h2>
        <div className="PgRD-Pricing-offers">
          {listOffer.map(
            ({ url, svg, price, quantity, text, isSpecialOffer }) =>
              isSpecialOffer ? (
                <SpecialOffer
                  key="SpecialOffer"
                  svg={svg}
                  setShowInfo={setShowInfo}
                  sampleReportRef={sampleReportRef}
                />
              ) : (
                <Offer
                  key={price}
                  url={url}
                  svg={svg}
                  price={price}
                  quantity={quantity}
                  text={text}
                  sampleReportRef={sampleReportRef}
                />
              )
          )}
        </div>
      </div>
    </section>
  );
};
Pricing.propTypes = {
  setShowInfo: PropTypes.func,
};
export default Pricing;
