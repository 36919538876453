import SvgUsIcon from "../../../assets/images/vector/pages/report-deals/FeedbackPopup/US_icon.svg";
import SvgUkIcon from "../../../assets/images/vector/pages/report-deals/FeedbackPopup/UK_icon.svg";
import SvgDeIcon from "../../../assets/images/vector/pages/report-deals/FeedbackPopup/DE_icon.svg";
import SvgItIcon from "../../../assets/images/vector/pages/report-deals/FeedbackPopup/IT_icon.svg";
import SvgFrIcon from "../../../assets/images/vector/pages/report-deals/FeedbackPopup/FR_icon.svg";
import SvgEsIcon from "../../../assets/images/vector/pages/report-deals/FeedbackPopup/ES_icon.svg";
import SvgCaIcon from "../../../assets/images/vector/pages/report-deals/FeedbackPopup/CA_icon.svg";
import SvgMxIcon from "../../../assets/images/vector/pages/report-deals/FeedbackPopup/MX_icon.svg";
import SvgInIcon from "../../../assets/images/vector/pages/report-deals/FeedbackPopup/IN_icon.svg";

const PG_RD_FP_CONTENT = {
	locationOfInterest: {
		listLocations: [
			{ icon: SvgUsIcon, location: "US" },
			{ icon: SvgUkIcon, location: "UK" },
			{ icon: SvgDeIcon, location: "DE" },
			{ icon: SvgItIcon, location: "IT" },
			{ icon: SvgFrIcon, location: "FR" },
			{ icon: SvgEsIcon, location: "ES" },
			{ icon: SvgCaIcon, location: "CA" },
			{ icon: SvgMxIcon, location: "MX" },
			{ icon: SvgInIcon, location: "IN" }
		],
		money: {
			"US": "$5,000",
			"UK": "£2,500",
			"DE": "€2,500",
			"IT": "€2,500",
			"FR": "€2,500",
			"ES": "€2,500",
			"CA": "CA$5,000",
			"MX": "MX$20,000",
			"IN": "₹75,000"
		}
	},
	categoriesOfInterest: {
		firstCategoryList: [
			"Automotive",
			"Electronics",
			"Tools & Home Improvement",
			"Patio, Lawn & Garden",
			"Cell Phones & Accessories",
			"Kitchen & Dining",
			"Camera & Photo",
			"Sports & Outdoors",
			"Home & Kitchen",
			"Toys & Games",
			"Baby",
			"Office Products"
		],
		secondCategoryList: [
			"Industrial & Scientific",
			"Musical Instruments",
			"Computers & Accessories",
			"Appliances",
			"Pet Supplies",
			"Arts, Crafts & Sewing",
			"Grocery & Gourmet Food",
			"Video Games",
			"Software",
			"Beauty & Personal Care",
			"Health & Household"
		]
	},
	preferredPrices: {
		priceList: ["Up to $30", "From $30 to $50", "Over $50", "Doesn't matter"]
	},
	differentProductSizes: {
		listOfAnswers: ["Yes", "No", "Doesn't matter"]
	},
	productSize: {
		sizeList: ["Small", "Standard", "Oversized", "Doesn't matter"]
	},
	criteriaSort: {
		listTitles: [
			"High number of sales",
			"Novelty of the product",
			"Low weight and small size",
			"Growing sales",
			"Low competition"
		]
	}
};
export default PG_RD_FP_CONTENT;
