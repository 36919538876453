import React from "react";
import PropTypes from "prop-types";
import TrackedLink from "../../../Analytics/TrackedLink";
import LinkSampleReport from "../../../common/LinkSampleReport";
import "./index.scss";

const Offer = ({
  url,
  svg,
  price,
  quantity,
  text = "Products Report",
  sampleReportRef,
}) => (
  <div className="PgRD-Pricing-Offer">
    <div className="PgRD-Pricing-Offer__limit">Limited Time Deal</div>
    <img src={svg} alt={`${quantity}`} />
    <div className="PgRD-Pricing-Offer__text">
      <span>{quantity} Booming</span> {text}
    </div>
    <div className="PgRD-Pricing-Offer__bonus">
      <span>BONUS</span> 1 Additional Product
    </div>
    <div className="PgRD-Pricing-Offer__price">{price}</div>
    <TrackedLink
      category="Index"
      action="Place an order"
      class="PgRD-Pricing-Offer__button"
      path={url}
    >
      Place an order
    </TrackedLink>
    <LinkSampleReport
      isReportDealsPage
      isScrollLink
      refParameter={sampleReportRef}
    />
  </div>
);
Offer.propTypes = {
  price: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  svg: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Offer;
