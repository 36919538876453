import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import SvgCloseIcon from "../../../../assets/images/vector/components/common/cn-modalClose.svg";
import "./index.scss";

const InfoForm = (props) => {
  const { closeInfo, getInfoState, handlerInputValue, handlerSubmit } = props;
  const { isShowInfoForm, email, firstName } = getInfoState;
  if (!isShowInfoForm) return null;

  return (
    <div className="PgRD-Info-Form">
      <img
        className="PgRD-Info-Form__close"
        src={SvgCloseIcon}
        alt="x"
        onClick={closeInfo}
      />
      <p className="PgRD-Info-Form__title">Please enter your information</p>
      <input
        placeholder="Email"
        className={getTrueClasses(
          "PgRD-Info-Form__input",
          "PgRD-Info-Form__input_email",
          email.error && "PgRD-Info-Form__input_error"
        )}
        value={email.value}
        name="email"
        maxLength="40"
        onChange={handlerInputValue}
      />
      <input
        placeholder="First Name or Company Name"
        className={getTrueClasses(
          "PgRD-Info-Form__input",
          firstName.error && "PgRD-Info-Form__input_error"
        )}
        value={firstName.value}
        name="firstName"
        maxLength="40"
        onChange={handlerInputValue}
      />
      <div className="PgRD-Info-Form__submit" onClick={handlerSubmit}>
        SUBMIT
      </div>
    </div>
  );
};
InfoForm.propTypes = {
  getInfoState: PropTypes.object,
  closeInfo: PropTypes.func,
  handlerSubmit: PropTypes.func,
  handlerInputValue: PropTypes.func,
};

export default InfoForm;
