import React, { useState } from "react";
import FastImage from "../../../../containers/common/FastImage";
import { graphql, useStaticQuery } from "gatsby";
import { getTrueClasses } from "../../../../helpers";
import "./index.scss";

const Video = () => {
  const [isShowVideo, setIsShowVideo] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { regex: "/RD-video/" }, ext: { eq: ".jpg" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                blurredOptions: { toFormat: AUTO }
                jpgOptions: { progressive: false }
              )
            }
            name
          }
        }
      }
    }
  `);

  const classes = getTrueClasses(
    "PgRD-HomeIntro-Video__inner",
    !isShowVideo && "PgRD-HomeIntro-Video__play"
  );
  return (
    <div className="PgRD-HomeIntro-Video">
      <div onClick={() => setIsShowVideo(true)} className={classes}>
        {isShowVideo ? (
          <iframe
            src="https://www.youtube.com/embed/heUBc94wE7U?autoplay=1&modestbranding=1"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            title="youtube"
          />
        ) : (
          <FastImage
            imageData={data}
            imageClassName="PgRD-HomeIntro-Video__img"
            imageAltName="home image"
            // isAdaptive
            isWithRatio
            defaultName="RD-video"
          />
        )}
      </div>
    </div>
  );
};

export default Video;
