import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import FPTitle from "../FPTitle";
import FPCategoryItems from "./FPCategoryItems";
import "./index.scss";

const FPCategory = (props) => {
  const {
    onClickCategory,
    categories,
    categoryLists,
    componentRef,
    setIsShowCategorySelectionPanel,
    isShowCategorySelectionPanel,
  } = props;
  const { firstCategoryList, secondCategoryList } = categoryLists;
  const { error, value } = categories;

  return (
    <div className="PgRD-FPCategory" ref={componentRef}>
      <FPTitle
        error={error}
        title="2. What categories are you interested in?"
      />
      <div className="PgRD-FPCategory__wrapper">
        <div
          className={getTrueClasses(
            "PgRD-FPCategory-dropDownList",
            error && "PgRD-FPCategory-dropDownList_error",
            value.length !== 0 && "PgRD-FPCategory-dropDownList_active"
          )}
          onClick={() => setIsShowCategorySelectionPanel(true)}
        >
          <div className="PgRD-FPCategory-dropDownList__placeholder">
            {value.length !== 0 ? (
              value.join(", ")
            ) : (
              <span>Select Amazon categories</span>
            )}
          </div>
          <div className="PgRD-FPCategory__triangle" />
        </div>
        {isShowCategorySelectionPanel && (
          <div
            className={getTrueClasses(
              "PgRD-FPCategory-selectionPanel",
              error && "PgRD-FPCategory-selectionPanel_error",
              value.length !== 0 && "PgRD-FPCategory-selectionPanel_active"
            )}
          >
            <div className="PgRD-FPCategory-selectionPanel-inner">
              <FPCategoryItems
                categoryList={firstCategoryList}
                value={value}
                onClickCategory={onClickCategory}
                customClass="firstCategoryList"
              />
              <FPCategoryItems
                categoryList={secondCategoryList}
                value={value}
                onClickCategory={onClickCategory}
                customClass="secondCategoryList"
              />
              <div
                className="PgRD-FPCategory__wrapperTriangle"
                onClick={() =>
                  setIsShowCategorySelectionPanel(!isShowCategorySelectionPanel)
                }
              >
                <div className="PgRD-FPCategory__triangle PgRD-FPCategory__triangle-active" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

FPCategory.propTypes = {
  categoryLists: PropTypes.object.isRequired,
  stateUpdateHandler: PropTypes.func.isRequired,
  isShowCategorySelectionPanel: PropTypes.bool.isRequired,
  setIsShowCategorySelectionPanel: PropTypes.func.isRequired,
  onClickCategory: PropTypes.func.isRequired,
  categories: PropTypes.object.isRequired,
};

export default FPCategory;
