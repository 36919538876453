import React from "react"
import Svg1 from "../../../assets/images/vector/pages/report-deals/Pricing/reportDeals-pricing-1.svg"
import Svg5 from "../../../assets/images/vector/pages/report-deals/Pricing/reportDeals-pricing-5.svg"
import Svg10 from "../../../assets/images/vector/pages/report-deals/Pricing/reportDeals-pricing-10.svg"
import Svg10_plus from "../../../assets/images/vector/pages/report-deals/Pricing/reportDeals-pricing-10_plus.svg"

const PG_RD_PRICING_CONTENT = {
  listOffer: [
    {
      url: "/checkout/?bundle=report_1product",
      svg: Svg1,
      price: "$187",
      quantity: 1,
      text: "Product Report",
    },
    {
      url: "/checkout/?bundle=report_5products",
      svg: Svg5,
      price: "$547",
      quantity: 5,
    },
    {
      url: "/checkout/?bundle=report_10products",
      svg: Svg10,
      price: "$997",
      quantity: 10,
    },
    {
      isSpecialOffer: true,
      svg: Svg10_plus,
    }
  ],

}


export default PG_RD_PRICING_CONTENT
