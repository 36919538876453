import React from "react";
import HOW_WE_DO_IT_ROAD_MAP from "./index.content";
import "./index.scss";

const RoadMap = () => {
  return (
    <div className="PgRD-HowWeDoIt-RoadMap">
      {HOW_WE_DO_IT_ROAD_MAP.map(({ img, text, title }) => (
        <div key={title} className="PgRD-HowWeDoIt-RoadMap__item">
          <img src={img} alt="" loading="lazy" />
          <p>{title}</p>
          <p>{text}</p>
        </div>
      ))}
    </div>
  );
};

export default RoadMap;
