import React from "react";
import PropTypes from "prop-types";
import Author from "./Author";
import Text from "./Text";
import BlockButton from "./BlockButton";
import "./index.scss";

const CustomersSay = ({ handlerClick, sampleReportRef }) => {
  return (
    <section className="PgRD-CS">
      <div className="PgRD-CS__successStory" id="success-story" />
      <div className="container">
        <h2 className="PgRD-CS__title">What our happy customers say</h2>
        <div className="PgRD-CS-content">
          <Text />
          <Author />
        </div>
      </div>
      <BlockButton
        handlerClick={handlerClick}
        sampleReportRef={sampleReportRef}
      />
    </section>
  );
};

export default CustomersSay;

CustomersSay.propTypes = {
  handlerClick: PropTypes.func,
};
