import React from "react";
import HOW_WE_DO_IT_SCREENSHOT from "./index.content";
import "./index.scss";

const Screenshot = () => {
  const {
    PNGXxl,
    PNGXxl2x,
    PNGLg,
    PNGLg2x,
    PNGMd,
    PNGMd2x,
    PNGMob,
    PNGMob2x,
    WEBPXxl,
    WEBPXxl2x,
    WEBPLg,
    WEBPLg2x,
    WEBPMd,
    WEBPMd2x,
    WEBPMob,
    WEBPMob2x,
  } = HOW_WE_DO_IT_SCREENSHOT;

  return (
    <div className="PgRD-HowWeDoIt-Points__Screenshot">
      <picture>
        <source
          type="image/webp"
          media="(min-width: 1366px)"
          srcSet={`${WEBPXxl} 1x, ${WEBPXxl2x} 2x`}
        />
        <source
          media="(min-width: 1366px)"
          srcSet={`${PNGXxl} 1x, ${PNGXxl2x} 2x`}
        />
        <source
          type="image/webp"
          media="(min-width: 992px) and (max-width: 1199px)"
          srcSet={`${WEBPLg} 1x, ${WEBPLg2x} 2x`}
        />
        <source
          media="(min-width: 992px) and (max-width: 1199px)"
          srcSet={`${PNGLg} 1x, ${PNGLg2x} 2x`}
        />
        <source
          type="image/webp"
          media="(min-width: 768px) and (max-width: 991px)"
          srcSet={`${WEBPMd} 1x, ${WEBPMd2x} 2x`}
        />
        <source
          media="(min-width: 768px) and (max-width: 991px)"
          srcSet={`${PNGMd} 1x, ${PNGMd2x} 2x`}
        />
        <source
          type="image/webp"
          media="(max-width: 767px)"
          srcSet={`${WEBPMob} 1x, ${WEBPMob2x} 2x`}
        />
        <source
          media="(max-width: 767px)"
          srcSet={`${PNGMob} 1x, ${PNGMob2x} 2x`}
        />
        <img src={PNGLg} alt="" loading="lazy" />
      </picture>
    </div>
  );
};

export default Screenshot;
