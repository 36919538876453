import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import FPTitle from "../FPTitle";
import "./index.scss";
import { useSelector } from "react-redux";

const FPSelectionSection = ({
  listOfAnswers,
  title,
  surveyFieldName,
  type,
  stateUpdateHandler,
  componentRef,
}) => {
  const {
    [surveyFieldName]: { error, value },
  } = useSelector((state) => state.fp);

  const checkAnswer = (currentAnswer, answer) => {
    if (type === "IS_VALUE_LIST") {
      return currentAnswer.length !== 0 && currentAnswer.includes(answer);
    }
    return currentAnswer === answer;
  };

  return (
    <div
      className={`PgRD-FPSelectionSection-${surveyFieldName}`}
      ref={componentRef}
    >
      <FPTitle error={error} title={title} />
      <div className={`PgRD-FPSelectionSection-${surveyFieldName}__wrapper`}>
        {listOfAnswers.map((answer, i) => (
          <div
            key={i}
            className={getTrueClasses(
              `PgRD-FPSelectionSection-${surveyFieldName}-item`,
              error && `PgRD-FPSelectionSection-${surveyFieldName}-item_error`,
              checkAnswer(value, answer) &&
                `PgRD-FPSelectionSection-${surveyFieldName}-item_active`
            )}
            onClick={() => {
              stateUpdateHandler(type, surveyFieldName, "value", answer);
            }}
          >
            <div
              className={`PgRD-FPSelectionSection-${surveyFieldName}-item__checkbox`}
            >
              <div
                className={`PgRD-FPSelectionSection-${surveyFieldName}-item__checkbox__inner`}
              />
            </div>
            <div
              className={`PgRD-FPSelectionSection-${surveyFieldName}-item__name`}
            >
              {answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

FPSelectionSection.propTypes = {
  listOfAnswers: PropTypes.array.isRequired,
  surveyFieldName: PropTypes.string.isRequired,
  stateUpdateHandler: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default FPSelectionSection;
