import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import ScrollLink from "../../../common/ScrollLink";

const BlockButton = ({ handlerClick, sampleReportRef }) => {
  return (
    <div className="PgRD-CS__BlockButton PgRD-CS-BlockButton">
      <div
        className="PgRD-CS-BlockButton__button PgRD-CS__individualReport"
        onClick={handlerClick}
      >
        GET THE INDIVIDUAL REPORT
      </div>
      <ScrollLink
        refParameter={sampleReportRef}
        classes="PgRD-CS-BlockButton__button PgRD-CS__sampleReport"
      >
        CHECK OUT THE SAMPLE REPORT
      </ScrollLink>
    </div>
  );
};

export default BlockButton;

BlockButton.propTypes = {
  handlerClick: PropTypes.func,
};
