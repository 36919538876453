import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import FP from "../../components/report-deals/FP";
import ParamsController from "../../helpers/paramsController";
import { useDispatch, useSelector } from "react-redux";
import { fpActions } from "../../store/slice/fp-slice";
import { checkValue } from "../../helpers";

const FPContainer = ({ isLoading, setIsLoading }) => {
  const [isFinalPopupState, setIsFinalPopupState] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const locationRef = useRef(null);
  const categoryRef = useRef(null);
  const preferredPricesRef = useRef(null);
  const differentProductSizesRef = useRef(null);
  const productSizeRef = useRef(null);
  const criteriaSortRef = useRef(null);
  const emailRef = useRef(null);

  const listRef = [
    locationRef,
    categoryRef,
    preferredPricesRef,
    differentProductSizesRef,
    productSizeRef,
    criteriaSortRef,
    emailRef,
  ];

  const state = useSelector((state) => state.fp);
  const dispatch = useDispatch();

  const updateState = (key, fieldName, value) => {
    dispatch(
      fpActions.updateState({
        key,
        fieldName,
        value,
      })
    );
  };

  const checkErrorIndexes = (array, index) => {
    const arr = [...array];
    if (array[index] === -1) {
      return true;
    } else if (array[index] === -2) {
      return false;
    }
    arr.splice(index, 1);
    return arr.includes(array[index]);
  };

  const checkIndexes = (indexes) =>
    indexes.findIndex((array, i) => checkErrorIndexes(indexes, i)) !== -1;

  const handlerInput = (key, fieldName, value) => {
    const inputValue = value.target.value;
    const name = value.target.name;
    const error =
      name === "email" ? !!checkValue("email", inputValue) : !inputValue;
    if (name === "criteriaSort") {
      updateState(key, fieldName, inputValue);
    } else {
      updateState(key, fieldName, inputValue);
      state[key].error !== null && updateState(key, "error", error);
    }
  };
  const handlerValue = (key, fieldName, value) => {
    updateState(key, fieldName, value);
    updateState(key, "error", !value);
  };
  const handlerValueList = (key, fieldName, value) => {
    let arr = [...state[key][fieldName]];
    if (arr.length === 0) {
      arr.push(value);
    } else {
      const index = arr.indexOf(value);
      const indexTwo = arr.indexOf("Doesn't matter");
      if (value === "Doesn't matter" && indexTwo === -1) {
        arr = ["Doesn't matter"];
      } else if (index === -1 && indexTwo !== -1) {
        arr.splice(indexTwo, 1);
        arr.push(value);
      } else {
        if (index !== -1) {
          arr.splice(index, 1);
        } else {
          arr.push(value);
        }
      }
    }
    updateState(key, fieldName, arr);
    updateState(key, "error", arr.length === 0);
  };
  const handlerValueListIndex = (key, fieldName, value, index) => {
    const array = [...state[key][fieldName]];
    array.splice(index, 1, value);
    updateState(key, fieldName, array);
    state[key].error !== null && updateState(key, "error", checkIndexes(array));
  };
  const stateUpdateHandler = (type, key, fieldName, value, index) => {
    switch (type) {
      case "IS_INPUT":
        handlerInput(key, fieldName, value);
        break;
      case "IS_VALUE":
        handlerValue(key, fieldName, value);
        break;
      case "IS_VALUE_LIST":
        handlerValueList(key, fieldName, value);
        break;
      case "IS_VALUE_LIST_INDEX":
        handlerValueListIndex(key, fieldName, value, index);
        break;
      default:
        break;
    }
  };

  const handleScrollIntoView = (i) => {
    !!listRef[i] &&
      !!listRef[i].current &&
      listRef[i].current.scrollIntoView({ block: "start", behavior: "smooth" });
  };
  const closeMessage = () => {
    localStorage.removeItem("openModal");
    setIsOpened(false);
  };

  useEffect(() => {
    const openQuest = new ParamsController().params.get("qst");
    setIsOpened(!!localStorage.getItem("openModal") || !!openQuest);
  }, []);

  useEffect(() => {
    const htmlBlock = document.getElementsByTagName("html")[0];
    htmlBlock.style.overflow = isOpened ? "hidden" : "auto";
  }, [isOpened]);

  useEffect(() => {
    isFinalPopupState && setTimeout(closeMessage, 7000);
  }, [isFinalPopupState]);

  if (!isOpened) {
    return null;
  }

  state.submit.isScrolled && handleScrollIntoView(state.submit.firstErrorIndex);

  return (
    <FP
      isFinalPopupState={isFinalPopupState}
      isLoading={isLoading}
      stateUpdateHandler={stateUpdateHandler}
      listRef={listRef}
      closeMessage={closeMessage}
      setIsFinalPopupState={setIsFinalPopupState}
      setIsLoading={setIsLoading}
      checkIndexes={checkIndexes}
      checkErrorIndexes={checkErrorIndexes}
      updateState={updateState}
    />
  );
};
FPContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default FPContainer;
