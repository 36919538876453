import React from "react";
import PropTypes from "prop-types";
import Points from "./Points";
import LinkSampleReport from "../../common/LinkSampleReport";
import Video from "./Video";
import "./index.scss";

const HomeIntro = ({ handlerClick, sampleReportRef }) => (
  <section className="PgRD-HomeIntro">
    <div className="container">
      <div className="PgRD-HomeIntro-row">
        <div className="PgRD-HomeIntro-info">
          <h1 className="PgRD-HomeIntro__title">
            Get the top booming product ideas to grow your Amazon business
          </h1>
          <Points />
          <div className="PgRD-HomeIntro__button" onClick={handlerClick}>
            Order your research
          </div>
          <LinkSampleReport
            isReportDealsPage
            isScrollLink
            refParameter={sampleReportRef}
          />
        </div>
        <Video />
      </div>
    </div>
  </section>
);

export default HomeIntro;

HomeIntro.propTypes = {
  handlerClick: PropTypes.func,
};
