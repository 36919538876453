import React from "react";
import SvgOne from "../../../../assets/images/vector/pages/report-deals/HowWeDoIt/reportDeals-section2-1.svg";
import SvgTwo from "../../../../assets/images/vector/pages/report-deals/HowWeDoIt/reportDeals-section2-2.svg";
import SvgThree from "../../../../assets/images/vector/pages/report-deals/HowWeDoIt/reportDeals-section2-3.svg";

const HOW_WE_DO_IT_ROAD_MAP = [
  {
    img: SvgOne,
    title: "We use AI to analyze millions of niches by multiple criteria",
    text: "to make a pool of the strongest product candidates. There are over 600 million products only on Amazon U.S., and we take full advantage of that variety."
  },
  {
    img: SvgTwo,
    title: "AMZScout experts hand-pick the best products and niches",
    text: "out of that pool and pack them into a PDF document"
  },
  {
    img: SvgThree,
    title: "it can be done for any product category",
    text: "It takes about a week to do the research"
  }
];
export default HOW_WE_DO_IT_ROAD_MAP;

