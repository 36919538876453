import React from "react";

const HOW_WE_DO_IT_POINTS = [
  <>
    A list of <b>exclusive, booming products tailored to your selected categories</b> and range of products
  </>,
  <>
    <b>Products that are outperforming by parameters</b> like profit margin, revenue, growth pace, sales velocity, low
    competition, and any other specific criteria you have in mind
  </>,
  <>
    <b>Keyword research for each product</b> to help you rank at the top of Amazon searches among competitors
  </>,
  <>
    <b>Great examples of local and global suppliers</b> in your marketplace for shipping new products
  </>
];
export default HOW_WE_DO_IT_POINTS;

