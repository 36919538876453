import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { checkValue } from "../../helpers";
import SubmitSheetController from "../../controllers/common/SubmitSheetController";
import Info from "../../components/report-deals/Info";

const InfoContainer = ({ setShowInfo, show }) => {
  const initInfoState = {
    email: { value: "", error: false },
    firstName: { value: "", error: false },
    isShowInfoForm: true,
    isCheckInputValue: false,
  };
  const [getInfoState, setInfoState] = useState({ ...initInfoState });

  const updateInfoState = (key, value, fieldName) => {
    setInfoState((prevState) => {
      const newState = { ...prevState };
      if (fieldName) {
        newState[key][fieldName] = value;
      } else {
        newState[key] = value;
      }
      return newState;
    });
  };
  const checkInputValue = (name, value) => {
    const error = name === "email" ? !!checkValue("email", value) : !value;
    updateInfoState(name, error, "error");
  };
  const handlerInputValue = (value) => {
    const inputValue = value.target.value;
    const name = value.target.name;
    updateInfoState(name, inputValue, "value");
    if (getInfoState.isCheckInputValue) {
      checkInputValue(name, inputValue);
    }
  };
  const closeInfo = () => {
    setInfoState({ ...initInfoState });
    setShowInfo(false);
  };
  const closeInfoForm = () => {
    updateInfoState("isShowInfoForm", false);
  };
  const handlerResult = () => {
    if (!getInfoState.email.error && !getInfoState.firstName.error) {
      const result = [getInfoState.email.value, getInfoState.firstName.value];
      new SubmitSheetController(result, closeInfoForm);
    }
  };
  const handlerSubmit = () => {
    checkInputValue("email", getInfoState.email.value);
    checkInputValue("firstName", getInfoState.firstName.value);
    updateInfoState("isCheckInputValue", true);
  };
  useEffect(() => {
    getInfoState.isCheckInputValue && handlerResult();
  }, [getInfoState.isCheckInputValue]);

  useEffect(() => {
    !getInfoState.isShowInfoForm && setTimeout(closeInfo, 5000);
  }, [getInfoState.isShowInfoForm]);

  useEffect(() => {
    const htmlBlock = document.getElementsByTagName("html")[0];
    htmlBlock.style.overflow = show ? "hidden" : "auto";
  }, [show]);

  if (!show) return null;

  return (
    <Info
      closeInfo={closeInfo}
      getInfoState={getInfoState}
      handlerSubmit={handlerSubmit}
      handlerInputValue={handlerInputValue}
    />
  );
};
InfoContainer.propTypes = {
  show: PropTypes.bool,
  setShowInfo: PropTypes.func,
};

export default InfoContainer;
