import React from "react";
import { getTrueClasses } from "../../../../helpers";
import "./index.scss";

const Item = ({ data, i }) => {
  const { mod, screen, text } = data;

  return (
    <div className={getTrueClasses("PgRD-Work-Item", `PgRD-Work-Item_${mod}`)}>
      {screen}
      <div className="PgRD-Work-Item__info">
        <p className="PgRD-Work-Item__number">0{i + 1}</p>
        <p className="PgRD-Work-Item__text">{text}</p>
      </div>
    </div>
  );
};

export default Item;
